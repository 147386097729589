//Dropdown Icons
$dropdown-icon-color: #333;
$dropdown-search-icon-color: #fff;
$dropdown-icon-close-color: #d32f2f;
$dropdown-loading-animation-color: #3b78e7;

$close-icon-url: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="' + $dropdown-icon-close-color +
  '" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>' !default;
$dropdown-arrow-icon-url: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="' + $dropdown-icon-color +
  '"  d="M16.6 8.6L12 13.2 7.4 8.6 6 10l6 6 6-6z"/></svg>' !default;
$search-icon-url: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="' + $dropdown-search-icon-color +
  '" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>' !default;
$loading-animation: '<svg enable-background="new 0 0 0 0" version="1.1" viewBox="0 0 100 100" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m73 50c0-12.7-10.3-23-23-23s-23 10.3-23 23m3.9 0c0-10.5 8.5-19.1 19.1-19.1s19.1 8.6 19.1 19.1" fill="' +
  $dropdown-loading-animation-color +
  '"><animateTransform attributeName="transform" attributeType="XML" dur="1s" from="0 50 50" repeatCount="indefinite" to="360 50 50" type="rotate"/></path></svg>' !default;

//Common Styles
$delete-color: #d32f2f !default;

//Dropdown Selector Styles
$dropdown-selector-background: #fff !default;
$dropdown-selector-border: #ddd !default;
$dropdown-selector-border-radius-value: 0 !default;
$dropdown-selector-text-color: #333 !default;

//Dropdown Selector Selected Styles
$dropdown-selector-selected-background: #f1f5f5 !default;
$dropdown-selector-selected-color: #333 !default;

//Dropdown Search Box Styles
$dropdown-selector-search-icon-background: #3b78e7 !default;
$dropdown-selector-search-icon-border-radius: 0 !default;
$dropdown-selector-search-input-background: #fff !default;
$dropdown-selector-search-input-border: #ddd !default;
$dropdown-selector-search-input-border-radius: 0 !default;
$dropdown-selector-search-input-text-color: #333 !default;

//Dropdown option Wrapper Styles
$dropdown-option-wrapper-border-radius-value: 0 !default;
$dropdown-option-wrapper-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2) !default;
$dropdown-option-wrapper-background: #fff !default;
$dropdown-option-wrapper-border: #ddd !default;
$dropdown-option-wrapper-text-color: #333 !default;
$dropdown-option-wrapper-divider-border: #ddd !default;

//Dropdown No Data Message Styles
$dropdown-no-data-message-text-color: #3b78e7 !default;

//Dropdown option Container Styles
$dropdown-option-container-border-radius-value: 0 !default;
$dropdown-option-container-wrapper-border: #ddd !default;

$dropdown-option-container-option-background: #fff !default;
$dropdown-option-container-option-text-color: #333 !default;
$dropdown-option-container-option-border: transparent !default;

$dropdown-option-container-option-hover-background: #ddd !default;
$dropdown-option-container-option-hover-text-color: #333 !default;

$dropdown-option-container-selected-option-background: #3b78e7 !default;
$dropdown-option-container-selected-option-text-color: #fff !default;

$dropdown-option-container-selected-option-hover-text-color: #fff !default;
$dropdown-option-container-selected-option-hover-background: #333 !default;

$dropdown-option-container-group-heading-background: #fff !default;
$dropdown-option-container-group-heading-border: #ddd !default;
$dropdown-option-container-group-heading-color: #333 !default;
$dropdown-option-container-group-heading-hover-background: none !default;
$dropdown-option-container-group-heading-hover-border: #ddd !default;
$dropdown-option-container-group-heading-hover-color: #333 !default;
