@import 'base.theme.variables';
@import '../mixins';

// Dropdown wrapper styles
// Dropdown Selector Delete Icon styles
// Dropdown Selector Arrow styles
// Dropdown Selector Loading Animation styles

.ng-dropdown {
  position: relative;

  * {
    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  // Dropdown Delete Button Container Styles
  .ng-dropdown-input-group-btn {
    height: 1.3em;
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.3em;
    z-index: 2;

    // Dropdown Delete Button Styles
    .ng-dropdown-delete-button {
      background: inline-svg($close-icon-url);
      background-size: contain;
      color: $delete-color;
      cursor: pointer;
      height: 100%;
      width: 100%;
    }
  }

  // Dropdown Arrow Styles
  .ng-dropdown-down-arrow {
    background: inline-svg($dropdown-arrow-icon-url);
    background-size: contain;
    cursor: pointer;
    height: 1.5em;
    position: absolute;
    right: 0.3rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5em;
    z-index: 1;
  }

  // Dropdown Loading Animation Styles
  .ng-dropdown-loading {
    position: absolute;
    right: 0;
    top: -0.1rem;
    z-index: 10;

    .ng-dropdown-loading-animation {
      background: inline-svg($loading-animation);
      height: 2rem;
      width: 2rem;
    }
  }

  // Dropdown Selector Styles
  .ng-dropdown-toggle {
    background: $dropdown-selector-background;
    border: 0.05rem solid $dropdown-selector-border;
    border-radius: $dropdown-selector-border-radius-value;
    color: $dropdown-selector-text-color;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    outline: 0;
    padding: 0.2rem 3rem 0.2rem 0.5rem;
    position: relative;
    text-align: left;
    width: 100%;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
      pointer-events: none;
    }

    .ng-dropdown-selected-option,
    .ng-dropdown-selected-placeholder {
      font-family: inherit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ng-dropdown-selected-value {
      background: $dropdown-selector-selected-background;
      color: $dropdown-selector-selected-color;
      display: inline-block;
      margin: 0 0.2rem 0 0.1rem;
      overflow: hidden;
      padding: 0 0.5rem;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.ng-dropdown-option-close-enabled {
        padding: 0 1.5rem 0 0.5rem;
      }

      .ng-dropdown-remove-selected-value {
        background: inline-svg($close-icon-url);
        background-size: contain;
        content: '';
        cursor: pointer;
        height: 1.2em;
        position: absolute;
        right: 0.3em;
        top: 50%;
        transform: translateY(-50%);
        width: 1.2em;
      }
    }
  }
}

// Dropdown Search Field Style
// Search Icon box Styles
// Search Input Filed Styles
// Delete Icon Styles

.ng-dropdown-search {

  * {
    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  .ng-dropdown-input-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;

    .ng-dropdown-input-group-addon {
      align-items: center;
      background: $dropdown-selector-search-icon-background;
      border: 0;
      border-radius: $dropdown-selector-search-icon-border-radius;
      display: flex;
      margin-bottom: 0;
      padding: 0.25rem 0.5rem;
      text-align: center;
      white-space: nowrap;
      width: 2em;

      &::after {
        background: inline-svg($search-icon-url);
        background-size: contain;
        content: '';
        cursor: pointer;
        height: 1.7em;
        left: 0.2em;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.7em;
      }
    }

    .ng-dropdown-input {
      align-items: center;
      background: $dropdown-selector-search-input-background;
      border: 0.05rem solid $dropdown-selector-search-input-border;
      border-radius: $dropdown-selector-search-input-border-radius;
      color: $dropdown-selector-search-input-text-color;
      display: flex;
      flex: 1 1 auto;
      margin-bottom: 0;
      outline: none;
      padding: 0.25rem 1.5rem 0.25rem 0.5rem;
      position: relative;
      width: 1%;
      z-index: 2;
    }
  }

  .ng-dropdown-input-group-btn {
    height: 1.7em;
    position: absolute;
    right: 0.3em;
    top: 50%;
    transform: translateY(-50%);
    width: 1.7em;
    z-index: 2;

    .ng-dropdown-delete-button {
      background: inline-svg($close-icon-url);
      background-size: contain;
      border: 0;
      cursor: pointer;
      height: 100%;
      outline: none;
      width: 100%;
    }
  }
}

// Dropdown Option List Styles
// Dropdown Option List Group Heading Styles
// Dropdown Option List Selected Options styles

.ng-dropdown-menu-wrapper {

  * {
    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  background-clip: padding-box;
  background-color: $dropdown-option-wrapper-background;
  border: 0.05rem solid $dropdown-option-wrapper-border;
  border-radius: $dropdown-option-wrapper-border-radius-value;
  box-shadow: $dropdown-option-wrapper-box-shadow;
  color: $dropdown-option-wrapper-text-color;
  display: block;
  height: 250px;
  list-style: none;
  margin: 0;
  padding: 1rem;
  position: absolute;
  text-align: left;
  width: 100%;
  z-index: 10001;

  .ng-dropdown-divider {
    border-top: 1px solid $dropdown-option-wrapper-divider-border;
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    padding: 0;
  }

  .ng-dropdown-option-container-wrapper {
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
    border: 0.07rem solid $dropdown-option-container-wrapper-border;
    border-radius: $dropdown-option-container-border-radius-value;
    height: 100%;
    overflow-y: auto;

    .ng-dropdown-option-container {
      height: 100%;
      list-style-type: none;
      margin: 0;
      padding: 0;

      .ng-dropdown-no-data {
        align-items: center;
        color: $dropdown-no-data-message-text-color;
        display: flex;
        height: 100%;
        justify-content: center;

        &:hover {
          background: none;
        }
      }

      li {
        background: $dropdown-option-container-option-background;
        border-bottom: 0.05rem solid $dropdown-option-container-option-border;
        cursor: pointer;
        overflow: hidden;
        padding: 0.25rem 0.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
          color: $dropdown-option-container-option-text-color;
        }

        &:hover {
          background: $dropdown-option-container-option-hover-background;

          span {
            color: $dropdown-option-container-option-hover-text-color;
          }
        }

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }

        &.ng-option-selected {
          background: $dropdown-option-container-selected-option-background;

          span {
            color: $dropdown-option-container-selected-option-text-color;
          }

          &:hover {
            background: $dropdown-option-container-selected-option-hover-background;
          }
        }

        &.ng-dropdown-group-heading {
          background: $dropdown-option-container-group-heading-background;
          border-bottom: 0.05rem solid;
          border-color: $dropdown-option-container-group-heading-border;
          color: $dropdown-option-container-group-heading-color;
          cursor: default;
          padding: 0.3rem;

          &:hover {
            background: $dropdown-option-container-group-heading-hover-background;
            border-color: $dropdown-option-container-group-heading-hover-border;
            color: $dropdown-option-container-group-heading-hover-color;
          }
        }

        span {
          padding-left: 0.5rem;
        }
      }
    }
  }

  .ng-dropdown-filter-wrapper {
    height: 3rem;

    + .ng-dropdown-option-container-wrapper {
      height: calc(100% - 3rem);
    }
  }
}
